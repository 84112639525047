<template>
  <div class="home">

    <!-- Not authenticated -->
    <div v-if="!isAuthenticated">
      <a href="#" @click.prevent="login" class="login-button">
        Generate Access Token
      </a>
    </div>
    <!-- / Not authenticated -->

    <!-- Authenticated -->
    <div v-if="isAuthenticated">
      <!-- <a href="#" @click.prevent="logout" class="login-button">
        Logout
      </a> -->

      <div class="access-token">
        <b style="margin-bottom: 20px; display: block;">Access Token:</b>
        <span class="access-token-selection">
        {{ $oidc.accessToken }}
        </span>
      </div>

      <div class="copy-to-clipboard">
        <a href="#" class="login-button" v-clipboard:copy="accessToken" v-clipboard:success="copySuccessful"
          v-clipboard:error="copyError">Copy to clipboard</a>
      </div>
    </div>
    <!-- / Authenticated -->

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  computed: {
    isAuthenticated() {
      return this.$oidc.isAuthenticated;
    },

    accessToken() {
      if (this.isAuthenticated) {
        return this.$oidc.accessToken;
      }
      return null;
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.logout();
    }
  },
  methods: {
    login() {
      this.$oidc.signIn();
    },

    logout() {
      this.$oidc.signOut();
      window.location.reload();
    },

    copySuccessful() {
      this.$toast.success('Copied to clipboard successfully');
    },

    copyError() {
      this.$toast.error('Error copying to clipboard');
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  text-align: center;
}

.login-button {
  background: #fff;
  color: #000;
  padding: 20px 80px;
  font-weight: 500;
  font-size: 17px;
  border-radius: 50px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  transition: background-color .2s ease;
  display: block;
  text-align: center;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  max-width: 250px;
}

.access-token {
  margin-top: 25px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  word-wrap: break-word;
}

.copy-to-clipboard {
  margin-top: 10px;
}

.access-token-selection {
  user-select: all !important;
}
</style>