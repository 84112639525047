import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  position: POSITION.TOP_CENTER
});

let profiles = {
  testing: {
    api_base: 'https://api.testing.teamsos.co.uk'
  },
  staging: {
    api_base: 'https://api.teamsos.co.uk'
  },
  live: {
    api_base: 'https://api.teamsos.co.uk'
  },
  alex: {
    api_base: 'https://id4api.alexfelton.com'
  }
}
let activeProfile = 'testing';

if (window.location.host == 'app.teamsos.co.uk') {
  activeProfile = 'live';
}

if (window.location.host == 'app.staging.teamsos.co.uk') {
  activeProfile = 'staging';
}

window.activeProfile = activeProfile
window.API_BASE = profiles[activeProfile].api_base;

import idsrvAuth from './idsrvAuth'

idsrvAuth.startup().then(ok => {
  if (ok) {
    // Axios Interceptor
    axios.interceptors.request.use(
      config => {
        const token = idsrvAuth.accessToken;
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      error => {
        Promise.reject(error)
      });

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  } else {
    console.log('Startup was not ok')
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
