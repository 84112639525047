// import { User } from 'oidc-client'
import Vue from 'vue'
import { createOidcAuth, SignInType, LogLevel } from 'vue-oidc-client/vue2'
import eventBus from './event-bus'

const loco = window.location
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`

let profiles = {
  testing: {
    authority: 'https://auth.testing.teamsos.co.uk',
    client_id: 'Teamsos.Testing.Web'
  },
  live: {
    authority: 'https://auth.teamsos.co.uk',
    client_id: 'Teamsos.Web'
  },
  staging: {
    authority: 'https://auth.teamsos.co.uk',
    client_id: 'Teamsos.Web'
  },
  alex: {
    authority: 'https://id4auth.alexfelton.com',
    client_id: 'Teamsos.Testing.Web'
  }
};
// let activeProfile = 'testing';
let activeProfile = 'testing';

// if (window.location.host == 'app.teamsos.co.uk') {
//   activeProfile = 'live';
// }

// if (window.location.host == 'app.staging.teamsos.co.uk') {
//   activeProfile = 'staging';
// }

// activeProfile = 'testing';

window.authActiveProfileAuthority = profiles[activeProfile].authority;

// switch (window.location.host) {
//   case 'app.teamsos.co.uk':
//     activeProfile = 'live';
//     break;
//   default:
//     activeProfile = 'testing';
//     break;
// }

let idsrvAuth = createOidcAuth(
  'main',
  SignInType.Popup,
  appRootUrl,
  {
    authority: profiles[activeProfile].authority,
    client_id: profiles[activeProfile].client_id, // 'implicit.shortlived',
    response_type: 'code',
    scope: 'openid id4api.read soteria',
    prompt: 'login',
    automaticSilentRenew: true
  },
  console,
  LogLevel.Info
)


// idsrvAuth = createOidcAuth(
//   'main',
//   SignInType.Popup,
//   appRootUrl,
//   {
//     authority: 'https://demo.identityserver.io/',
//     client_id: 'interactive.public', // 'implicit.shortlived',
//     response_type: 'code',
//     scope: 'openid profile email api',
//     // test use
//     prompt: 'login'
//   },
//   console,
//   LogLevel.Debug
// )

// handle events
idsrvAuth.events.addAccessTokenExpiring(function () {
  // eslint-disable-next-line no-console
  console.log('access token expiring')
  // alert('access token expiring');
})

idsrvAuth.events.addAccessTokenExpired(function () {
  // eslint-disable-next-line no-console
  console.log('access token expired')
  // alert('access token expired');
  eventBus.$emit('idsrv-logout');
})

idsrvAuth.events.addSilentRenewError(function (err) {
  // eslint-disable-next-line no-console
  console.error('silent renew error', err);
  // idsrvAuth.logout();
  eventBus.$emit('idsrv-logout');
})

idsrvAuth.events.addUserLoaded(function (user) {
  // eslint-disable-next-line no-console
  console.log('user loaded', user)
  eventBus.$emit('user-loaded')
})

idsrvAuth.events.addUserUnloaded(function () {
  // eslint-disable-next-line no-console
})

idsrvAuth.events.addUserSignedOut(function () {
  // eslint-disable-next-line no-console
  console.log('user signed out')
})

idsrvAuth.events.addUserSessionChanged(function () {
  // eslint-disable-next-line no-console
  console.log('user session changed')
})

// a little something extra
Vue.prototype.$oidc = idsrvAuth

export default idsrvAuth